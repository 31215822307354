import React, { useRef } from "react"
import { TitleWithDescriptionBlock } from "../moleculs/title-with-description-block"
import { withStyles } from "@material-ui/core"
import withWidth, { isWidthDown } from "@material-ui/core/withWidth"

import Grid from "@material-ui/core/Grid"
import useShouldAnimate from "../../hooks/useShouldAnimate"
import useScrollPosition from "../../hooks/useScrollPosition"
import { ContentContainer } from "../moleculs/content-container"
import Typography from "@material-ui/core/Typography"
import { MeteorLogo } from "../svg/meteor-logo"
import { Icon30x } from "../svg/icon-30x"
import { ClientRabbit } from "../svg/client-rabbit"
import { useFluidUrl } from "../images"
import { useInView } from "react-intersection-observer"

function MeteorExpertiseBlock(props) {
  const { classes, width, title, description } = props
  const scrollPosition = useScrollPosition()
  const sectionRef = useRef()
  const sectionPosition = sectionRef?.current?.offsetTop
  const shouldAnimate = useShouldAnimate(sectionPosition, scrollPosition)
  const rabbitLogo = useFluidUrl('clientFeedbackLogo1')
  const selfleadersLogo = useFluidUrl('clientFeedbackLogo2')
  const spacing = 1

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })


  function renderDurationTile () {
    return (
      <div className={`${classes.tile} ${classes.durationContainer}`}>
        <Typography
          variant="h4"
          className={classes.durationTitle}
          children={'9 years with'}
        />
        <MeteorLogo/>
      </div>
    )
  }

  function renderScalingInfoTile () {
    return (
      <div className={`${classes.tile} ${classes.scalingContainer}`}>
        <Typography
          variant="h6"
          className={classes.smallTitle}
          children={'Experience of scaling a project'}
        />
        <Icon30x className={classes.scalingIcon}/>
      </div>
    )
  }

  function renderProductsTile () {
    return (
      <div className={`${classes.tile} ${classes.productsContainer}`}>
        <Typography
          variant="h2"
          className={classes.productsTitle}
          children={'6 product projects built ground up'}
        />
        <div className={classes.projectsList}>
          <Typography
            variant="subtitle1"
            children={'Knotel Booking'}
            className={classes.projectName}
          />
          <Typography
            variant="subtitle1"
            children={'TechGC Members'}
            className={classes.projectName}
          />
          <Typography
            variant="subtitle1"
            children={'TechGC Mavenbase'}
            className={classes.projectName}
          />
          <Typography
            variant="subtitle1"
            children={'TechGC Partners'}
            className={classes.projectName}
          />
          <Typography
            variant="subtitle1"
            children={'Astraload SaaS'}
            className={classes.projectName}
          />
          <Typography
            variant="subtitle1"
            children={'Cyber Quest'}
            className={classes.projectName}
          />
        </div>
      </div>
    )
  }

  function renderRecentExamplesTile () {
    return (
      <div className={`${classes.tile} ${classes.recentExamplesContainer}`}>
        <Typography
          variant="body1"
          className={classes.smallTitle}
          children={'Recent noticeable examples'}
        />

        <Typography
          variant="h4"
          className={classes.exampleTitle}
          children={'Astraload SaaS'}
        />

        <Typography
          variant="h4"
          className={classes.exampleTitle}
          children={'Cyber Quest'}
        />
      </div>
    )
  }

  function renderImpactedProjectTile () {
    return (
      <div className={`${classes.tile} ${classes.impactedProjectsContainer}`}>
        <Typography
          variant="h2"
          className={classes.impactedProjectsTitle}
        >
          Have significantly impacted 4&nbsp;projects
        </Typography>
        <div className={`${classes.projectsList} ${classes.impactedProjects}`}>
          <div>
            <Typography
              variant="body1"
              children={'Knotable'}
            />
            <Typography
              variant="body1"
              children={'Selfleaders'}
            />
          </div>
          <div>
            <Typography
              variant="body1"
              children={'White Rabbit'}
            />
            <Typography
              variant="body1"
              children={'MaestroQA'}
            />
          </div>
        </div>
      </div>
    )
  }

  function renderBlogTile () {
    return (
      <div className={`${classes.tile} ${classes.bottomTile}`}>
        <Typography
          variant="body1"
          className={classes.smallTitle}
          children={'Freshly written articles'}
        />

        <Typography
          variant="h4"
          className={classes.astraloadPost}
          children={'A short introduction to "Perf team"'}
        />

        <Typography
          variant="h4"
          className={classes.astraloadPost}
          children={'Design system on Meteor'}
        />
      </div>
    )
  }

  function renderFeedbackTile () {
    return (
      <div className={`${classes.tile} ${classes.feedbackTileContainer}`} >
        <Typography
          variant="body1"
          className={classes.smallTitle}
          children={'Warm feedback from our clients'}
        />

        <div className={classes.clientsContainer}>
          <div><img src={rabbitLogo.src} alt="" /></div>
          <ClientRabbit />
          <div><img src={selfleadersLogo.src} alt="" /></div>
        </div>
      </div>
    )
  }

  function renderDesktopBlocks() {
    return (
      <Grid container spacing={spacing}>
        <Grid
          item
          md={3}
        >
          <div className={classes.tileWrapper}>
            {renderDurationTile()}
          </div>
          <div className={classes.tileWrapper}>
            {renderRecentExamplesTile()}
          </div>
        </Grid>
        <Grid
          item
          md={5}
        >
          {renderProductsTile()}
        </Grid>
        <Grid
          item
          md={4}
        >
          <div className={classes.tileWrapper}>
            {renderScalingInfoTile()}
          </div>
          <div className={classes.tileWrapper}>
            {renderImpactedProjectTile()}
          </div>
        </Grid>
        <Grid
          item
          md={6}
        >
          {renderBlogTile()}
        </Grid>
        <Grid
          item
          md={6}
        >
          {renderFeedbackTile()}
        </Grid>
      </Grid>
    )
  }

  function renderMobileBlocks() {
    return(
      <Grid
        container
        spacing={spacing}
      >
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
        >
          {renderDurationTile()}
        </Grid>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
        >
          {renderScalingInfoTile()}
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
          {renderProductsTile()}
        </Grid>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
        >
          {renderRecentExamplesTile()}
        </Grid>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
        >
          {renderImpactedProjectTile()}
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
          {renderBlogTile()}
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
          {renderFeedbackTile()}
        </Grid>
      </Grid>
    )
  }

  function setItemClass () {
    if (inView) return `${classes.container} ${classes.itemAnimation}`
    return classes.container
  }

  function renderExpertiseTiles () {
    const block = isWidthDown('md', width) ?
      renderMobileBlocks() :
      renderDesktopBlocks()

    return (
      <div className={setItemClass()} ref={ref}>
        {block}
      </div>
    )
  }

  return (
    <ContentContainer ref={sectionRef}>
      <div>
        <TitleWithDescriptionBlock
          title={title}
          description={description}
          left={true}
          shouldAnimate={shouldAnimate}
        />

        {renderExpertiseTiles()}
      </div>
    </ContentContainer>
  )
}

const styles = theme => {
  const gradientText = {
    backgroundColor: "#4D18DD",
    backgroundImage: "linear-gradient(180deg, #BF31D7, #4D18DD)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent"
}

  return {
    ...theme.animations.fadeInUp,
    container: {
      marginTop: 100,
      textAlign: "center",
      opacity: 0,

      [theme.breakpoints.down("sm")]: {
        marginTop: 50
      },
    },

    tile: {
      borderRadius: 20,
      padding: "30px 30px 40px",
      background: "#ECF2F6",
      boxSizing: "border-box",
      height: "100%",

      [theme.breakpoints.down("sm")]: {

      },
    },

    scalingContainer: {
      display: "flex",
      alignItems: "center",
      padding: "25px 30px",

      "& $smallTitle": {
        textAlign: "left",
        margin: 0,

        [theme.breakpoints.down("md")]: {
          marginBottom: 20
        },

        [theme.breakpoints.down("xs")]: {
          textAlign: "center",
        },
      },

      [theme.breakpoints.down("md")]: {
        flexDirection: "column"
      },
    },

    scalingIcon: {
      marginLeft: 16,

      [theme.breakpoints.down("sm")]: {
        margin: 0
      },
    },

    productsContainer: {
      padding: "40px 30px 47px"
    },

    productsTitle: {
      fontSize: 58,
      lineHeight: "68px",
      fontWeight: 700,
      marginBottom: 30,
      letterSpacing: "-0.5px",
      ...gradientText,

      [theme.breakpoints.down("md")]: {
        maxWidth: "50%",
        margin: "0 auto 30px"
      },

      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        fontSize: 38,
        lineHeight: "48px"
      },
    },

    durationContainer: {
      padding: "37px 30px 45px",

      [theme.breakpoints.down("md")]: {
        padding: 30
      },

      "& svg": {
        display: "block",
        margin: "0 auto"
      }
    },

    durationTitle: {
      fontSize: 30,
      lineHeight: "48px",
      color: "#100A23",
      fontWeight: 700,
      letterSpacing: -1,
      marginBottom: 8
    },

    impactedProjectsContainer: {
      padding: 30
    },

    impactedProjectsTitle: {
      color: theme.palette.text.secondary,
      marginBottom: 45,
      fontSize: 38,
      lineHeight: "48px",
      fontWeight: 700,

      [theme.breakpoints.down("sm")]: {
        marginBottom: 40
      },
    },

    recentExamplesContainer: {
      padding: "47px 30px",

      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      },

      [theme.breakpoints.down("sm")]: {
        display: "block"
      },

      "& $smallTitle": {
        marginBottom: 30
      }
    },

    exampleTitle: {
      color: theme.palette.text.secondary,
      ...gradientText,
      letterSpacing: "-0.5px",
      fontSize: 30,
      lineHeight: "40px",
      fontWeight: 700,

      "&:not(:last-child)": {
        marginBottom: 8
      }
    },

    bottomTile: {
      padding: "30px 20px",

      "& $smallTitle": {
        marginBottom: 30
      }
    },

    feedbackTileContainer: {
      padding: 40,

      "& $smallTitle": {
        marginBottom: 30
      }
    },

    text: {
      color: theme.palette.text.secondary,
    },

    smallTitle: {
      color: theme.palette.text.secondary,
      fontSize: 18,
      lineHeight: "24px",
      marginBottom: 20,
      fontWeight: 400,
      letterSpacing: 0,

      [theme.breakpoints.down("sm")]: {
        marginBottom: 30
      },
    },

    projectsList: {
      columnCount: 2,
      gap: 30,

      "&$impactedProjects": {
        "& > div:first-child": {},
        "& > div:last-child": {
          textAlign: "right"
        }
      },

      [theme.breakpoints.down("sm")]: {
        columnCount: 1
      },
    },

    impactedProjects: {
      columnCount: 2,
      gap: 30,

      "& p": {
        fontSize: 18,
        lineHeight: "24px",
        fontWeight: 400,

        "&:not(:last-child)": {
          marginBottom: 8
        }
      },

      "& > div:first-child": {
        textAlign: "left"
      },

      "& > div:last-child": {
        textAlign: "right"
      }
    },

    projectName: {
      marginBottom: 16,

      "&:nth-child(3n)": {
        marginBottom: 0,

        [theme.breakpoints.down("sm")]: {
          "&:not(:last-child)": {
            marginBottom: 16
          },
        },
      }
    },

    astraloadPost: {
      color: theme.palette.text.secondary,
      fontWeight: 700,

      "&:not(:last-child)": {
        marginBottom: 8
      }
    },

    clientsContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      "& img": {
        width: "100%"
      },

      "& > div:first-child": {
        width: 152
      },

      "& > div:last-child": {
        width: 181
      },

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",

        "& > *:not(:last-child)": {
          marginBottom: 16
        }
      },
    },
    tileWrapper: {
      "&:not(:last-child)": {
        marginBottom: 5
      }
    },
    itemAnimation: {
      ...theme.animations.itemFadeInUp,

      [theme.breakpoints.down("sm")]: {
        ...theme.animations.noAnimation,
      },
    },
  }
}


// eslint-disable-next-line
MeteorExpertiseBlock = withWidth()(withStyles(styles)(MeteorExpertiseBlock))

export { MeteorExpertiseBlock }

import React from "react"
import { withStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { ContentContainer } from "./content-container"
import { CallToActionButton } from "./call-to-action-button"
import withWidth, { isWidthDown } from "@material-ui/core/withWidth"
import {
  MeteorIcon,
  NodeJSIcon,
  JSIcon,
  TSIcon,
  ReactIcon
} from "../svg/technologies-icon"
import { Robust } from "../svg/robust"
import { Rapid } from "../svg/rapid"
import { Joyful } from "../svg/joyful"
import { Agile } from "../svg/agile"
import { useInView } from "react-intersection-observer"
import useScrollPosition from "../../hooks/useScrollPosition"
import useShouldAnimate from "../../hooks/useShouldAnimate"

function IntroduceMainBlock(props) {
  const { classes, width } = props

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    delay: 500,
  })

  const scrollPosition = useScrollPosition()
  const sectionRef = React.useRef()
  const sectionPosition = sectionRef?.current?.offsetTop
  const shouldAnimate = useShouldAnimate(sectionPosition, scrollPosition)

  function prepareAnimationClasses(className) {
    if (!shouldAnimate) return `${className} ${classes.visible}`
    if (inView) return `${className} ${classes.itemAnimation}`
    return className
  }

  function renderTechnologyIcons() {
    return (
      <div className={classes.technologies}>
        <div className={classes.technologiesInner}>
          <MeteorIcon className={classes.meteorIcon}/>
          <NodeJSIcon className={classes.nodeJsIcon}/>
          <JSIcon className={classes.jsIcon}/>
          <TSIcon className={classes.jsIcon}/>
          <ReactIcon className={classes.reactIcon}/>
        </div>
      </div>
    )
  }

  function renderAnimatedWords() {
    return (
      <div className={classes.animatedWords}>
        <span className={`${classes.word} ${classes.robust}`} />
        <span className={`${classes.word} ${classes.rapid}`} />
        <span className={`${classes.word} ${classes.joyful}`} />
        <span className={`${classes.word} ${classes.agile}`} />
      </div>
    )
  }

  function renderMainAnimation() {
    if(isWidthDown('md', width)) return null

    return (
      <div className={classes.mainAnimationBlock}>
        <Robust className={classes.animatedIcon} />
        <Rapid className={classes.animatedIcon} />
        <Joyful className={classes.animatedIcon} />
        <Agile className={classes.animatedIcon} />
        <div className={classes.coverLayer} />
      </div>
    )
  }

  return (
    <ContentContainer customClass={classes.wrapper}>
      <div className={classes.container} ref={ref}>
        <Typography variant="h1" className={classes.title}>
          Let's build {renderAnimatedWords()} <br />
          software together
        </Typography>

        <Typography
          variant="h4"
          className={classes.description}
        >
          Astraload – elegant solutions by talented professionals
        </Typography>

        <CallToActionButton
          desktopText="See services"
          mobileText="See services"
          scrollTo="ourServicesBlock"
        />

        {renderMainAnimation()}
      </div>

      {renderTechnologyIcons()}
    </ContentContainer>
  )
}

const styles = theme => ({
  ...theme.animations.rotation,
  ...theme.animations.fadeInUp,

  "@keyframes fadeInOut": {
    "0%": { opacity: 0 },
    "4%": { opacity: 0},
    "11%": { opacity: 1 },
    "15%": { opacity: 1 },
    "23%": { opacity: 0 },
    "80%": { opacity: 0 },
    "100%": { opacity: 0 }
  },

  "@keyframes animateWord": {
    "0%": { opacity: 0 },
    "3%": { opacity: 1 },
    "13": { opacity: 1 },
    "22%": { opacity: 1 },
    "24%": { opacity: 0 },
    "80%": { opacity: 0 },
    "100%": { opacity: 0 }
  },

  "@keyframes typing": {
    from: { width: 0 },
    to: { width: "100%" }
  },

  "@keyframes blink": {
    from: { opacity: 0.6 },
    "50%": { opacity: 0.2 },
    to: { opacity: 0.6 },
  },

  wrapper: {
    minHeight: "80vh",

    [theme.breakpoints.down("md")]: {
      minHeight: "auto"
    },
  },

  container: {
    maxWidth: 570,
    position: "relative"
  },
  title: {
    fontSize: 64,
    lineHeight: "65px",
    color: theme.palette.text.secondary,
    marginBottom: 30,
    position: "relative",
    ...theme.animations.itemFadeInUp,

    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
      lineHeight: "40px",
      marginTop: 20
    },
  },
  description: {
    color: theme.palette.text.primary,
    fontWeight: 300,
    letterSpacing: "-0.5px",
    marginBottom: 80,
    ...theme.animations.itemFadeInUp,

    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: "30px",
    },
  },
  technologies: {
    marginTop: 100,
    ...theme.animations.itemFadeInUp,
    animationDelay: '0.4s',

    [theme.breakpoints.down("sm")]: {
      marginTop: 50
    },
  },
  technologiesInner: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      margin: -12,
      width: "auto",
      justifyContent: "flex-start",
    },

    "& > *": {
      marginRight: 30,

      [theme.breakpoints.down("sm")]: {
        margin: 0,
        padding: 12
      },
    }
  },
  meteorIcon: {
    [theme.breakpoints.down("sm")]: {
      "& svg": {
        width: 133
      }
    },
  },
  nodeJsIcon: {
    [theme.breakpoints.down("sm")]: {
      "& svg": {
        width: 114
      }
    },
  },
  jsIcon: {
    [theme.breakpoints.down("sm")]: {
      "& svg": {
        width: 32
      }
    },
  },
  reactIcon: {
    [theme.breakpoints.down("sm")]: {
      "& svg": {
        width: 93
      }
    },
  },
  animatedWords: {
    display: "inline-block",
  },
  word: {
    position: "absolute",
    top: 0,
    color: "#6F28DB",
    "--caret": "#6F28DB",
    opacity: 0,
    animation: "$animateWord 20s infinite linear 0s",
    display: "flex",
    justifyContent: "flex-start",

    "&:nth-child(2)": {
      animationDelay: "5s",
    },
    "&:nth-child(3)": {
      animationDelay: "10s",
    },
    "&:nth-child(4)": {
      animationDelay: "15s",
    },

    "&:before": {
      content: '""',
      display: "inline-block",
    },

    "&:after": {
      //content: '""',
      backgroundColor: "#6F28DB",
      animation: "$blink 1.2s linear infinite",
      width: 30,
      height: 50,
      position: "relative",
      right: -5,
      bottom: -5,
      opacity: 0.8
    }
  },
  robust: {
    "&::before": {
      content: '""',
      animation: "typingRobust 20s forwards infinite"
    }
  },
  rapid: {
    "&::before": {
      content: "''",
      animation: "typingRapid 20s forwards infinite",
      animationDelay: "5s",
    }
  },
  joyful: {
    "&::before": {
      content: "''",
      animation: "typingJoyful 20s forwards infinite",
      animationDelay: "10s",
    }
  },
  agile: {
    "&::before": {
      content: "''",
      animation: "typingAgile 20s forwards infinite",
      animationDelay: "15s",
    }
  },
  mainAnimationBlock: {
    position: "absolute",
    bottom: 0,
    right: 0,
    transform: "translate(120%, 0)",
    width: 416,
    height: 416,
    overflow: "hidden"
  },
  animatedIcon: {
    position: "absolute",
    opacity: 0,
    animation: "$fadeInOut 20s infinite linear 0s",

    "&:nth-child(1)": {

    },
    "&:nth-child(2)": {
      animationDelay: "5s"
    },
    "&:nth-child(3)": {
      animationDelay: "10s"
    },
    "&:nth-child(4)": {
      animationDelay: "15s"
    },
  },
  coverLayer: {
    position: "absolute",
    width: 600,
    height: 600,
    background: "radial-gradient(111.18% 111.18% at 64.42% 19.04%, rgba(255, 255, 255, 0) 24.83%, #FFFFFF 49.59%)",
    zIndex: 2,
    left: "-15%",
    top: "-15%",
    transformOrigin: "center center",
    ...theme.animations.infiniteRotation,

    "&:after": {
      content: '""',
      width: "100%",
      height: "100%",
      bottom: 0,
      left: 0,
      position: "absolute",
      backdropFilter: "blur(8px)",
      mask: "linear-gradient(transparent, black 80%)"
    }
  }
})

// eslint-disable-next-line
IntroduceMainBlock = withWidth()(withStyles(styles)(IntroduceMainBlock))

export { IntroduceMainBlock }

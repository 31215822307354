import React from "react"
import { withStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import JourneyIcons from "../svg/journey-icons"
import { LinkWithArrow } from "./link-with-arrow"
import { SubscribeEmailForm } from "./subscribe-email-form"

function JourneySocialMediaItem(props) {
  const { classes, icon, text, linkText, link } = props
  const IconComponent = JourneyIcons[icon]

  function renderFooterComponent () {
    if (linkText) return (
      <LinkWithArrow
        text={linkText}
        link={link}
      />
    )

    return <SubscribeEmailForm/>
  }

  return (
    <div className={classes.item}>
      <div className={classes.icon}>
        <IconComponent/>
      </div>
      <div className={classes.info}>
        <Typography
          variant="body2"
          className={classes.text}
          children={text}
        />
        {renderFooterComponent()}
      </div>
    </div>
  )
}

const styles = theme => ({
  icon: {
    marginBottom: 30,

    "& svg": {
      display: "block"
    }
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1
  },
  text: {
    marginBottom: 30
  },
  itemsContainer: {
    marginTop: 100,

    [theme.breakpoints.down("sm")]: {
      marginTop: 50
    },
  },
  item: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    boxSizing: "border-box",
  }
})

// eslint-disable-next-line
JourneySocialMediaItem = withStyles(styles)(JourneySocialMediaItem)

export { JourneySocialMediaItem }

import React from "react"

function Robust({ className }) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="416" height="416" viewBox="0 0 416 416" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M216.939 37.6099C211.925 32.8618 204.075 32.8618 199.061 37.6099C164.104 70.7161 116.935 91.0018 65 91.0018C64.1737 91.0018 63.3487 90.9967 62.5249 90.9865C56.8506 90.916 51.7863 94.5343 50.0139 99.9251C42.8631 121.674 39 144.9 39 169.003C39 271.998 109.444 358.507 204.76 383.039C206.885 383.586 209.115 383.586 211.24 383.039C306.556 358.507 377 271.998 377 169.003C377 144.9 373.137 121.674 365.986 99.9251C364.214 94.5343 359.149 90.916 353.475 90.9865C352.651 90.9967 351.826 91.0018 351 91.0018C299.065 91.0018 251.896 70.7161 216.939 37.6099ZM270.579 176.556C274.752 170.714 273.398 162.595 267.556 158.421C261.714 154.248 253.595 155.602 249.421 161.444L193.341 239.956L165.192 211.808C160.116 206.731 151.884 206.731 146.808 211.808C141.731 216.884 141.731 225.116 146.808 230.192L185.808 269.192C188.51 271.895 192.264 273.271 196.072 272.956C199.881 272.641 203.357 270.666 205.579 267.556L270.579 176.556Z" fill="#D0DAE1"/>
      </svg>
    </div>
  )
}

export { Robust }
import React from "react"

function Agile({ className }) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="416" height="416" viewBox="0 0 416 416" fill="none">
        <path d="M325 221L351 221C358.18 221 364 215.18 364 208C364 200.82 358.18 195 351 195L325 195C317.82 195 312 200.821 312 208C312 215.18 317.82 221 325 221Z" fill="#D0DAE1"/>
        <path d="M208 104C208 96.8203 213.82 91 221 91L351 91.0003C358.18 91.0003 364 96.8206 364 104C364 111.18 358.18 117 351 117L221 117C213.82 117 208 111.18 208 104Z" fill="#D0DAE1"/>
        <path d="M208 312C208 304.82 213.82 299 221 299L351 299C358.18 299 364 304.821 364 312C364 319.18 358.18 325 351 325L221 325C213.82 325 208 319.18 208 312Z" fill="#D0DAE1"/>
        <path d="M65.0001 117L91.0001 117C98.1798 117 104 111.18 104 104C104 96.8202 98.1796 90.9999 90.9999 91L64.9999 91.0002C57.8202 91.0003 51.9999 96.8207 52 104C52.0001 111.18 57.8204 117 65.0001 117Z" fill="#D0DAE1"/>
        <path d="M91.0001 325L65.0001 325C57.8204 325 52.0001 319.18 52 312C51.9999 304.821 57.8202 299 64.9999 299L90.9999 299C98.1796 299 104 304.82 104 312C104 319.18 98.1798 325 91.0001 325Z" fill="#D0DAE1"/>
        <path d="M52 208C52 200.82 57.8203 195 65 195H195C202.18 195 208 200.82 208 208C208 215.18 202.18 221 195 221H65C57.8203 221 52 215.18 52 208Z" fill="#D0DAE1"/>
        <path d="M156 65C134.461 65 117 82.4609 117 104C117 125.539 134.461 143 156 143C177.539 143 195 125.539 195 104C195 82.4609 177.539 65 156 65Z" fill="#D0DAE1"/>
        <path d="M221 208C221 186.461 238.461 169 260 169C281.539 169 299 186.461 299 208C299 229.539 281.539 247 260 247C238.461 247 221 229.539 221 208Z" fill="#D0DAE1"/>
        <path d="M156 273C134.461 273 117 290.461 117 312C117 333.539 134.461 351 156 351C177.539 351 195 333.539 195 312C195 290.461 177.539 273 156 273Z" fill="#D0DAE1"/>
      </svg>
    </div>
  )
}

export { Agile }
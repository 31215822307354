import React from "react"

function MeteorIcon({ className }) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="300" height="217" viewBox="0 0 300 217" fill="none">
        <g clip-path="url(#clip0_689_12577)">
          <path d="M0.304688 -78.4199L257.485 190.686C257.485 190.686 266.247 196.79 272.946 189.668C279.647 182.546 274.493 175.425 274.493 175.425L0.305863 -78.4188L0.304688 -78.4199ZM81.7368 -52.9845L277.586 155.584C277.586 155.584 286.347 161.69 293.047 154.567C299.748 147.446 294.594 140.324 294.594 140.324L81.7368 -52.9845ZM24.5279 1.44763L220.376 210.016C220.376 210.016 229.137 216.121 235.837 208.998C242.538 201.877 237.384 194.755 237.384 194.755L24.5279 1.44763ZM150.576 -31.1104L287.405 114.605C287.405 114.605 293.527 118.869 298.207 113.894C302.888 108.918 299.287 103.943 299.287 103.943L150.577 -31.1104H150.576ZM43.375 64.5266L180.203 210.242C180.203 210.242 186.325 214.506 191.005 209.53C195.686 204.555 192.085 199.58 192.085 199.58L43.375 64.5266ZM220.893 0.42961L282.908 66.6729C282.908 66.6729 285.936 68.6718 288.251 66.3397C290.566 64.0088 288.785 61.6768 288.785 61.6768L220.893 0.42961ZM77.6134 131.675L139.628 197.919C139.628 197.919 142.657 199.917 144.972 197.587C147.286 195.254 145.506 192.924 145.506 192.924L77.6134 131.675Z" fill="url(#paint0_linear_689_12577)"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear_689_12577" x1="183.471" y1="145.5" x2="183.455" y2="-23.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          <clipPath id="clip0_689_12577">
            <rect width="300" height="300" fill="white" transform="translate(0 -83)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

function CodeIcon({ className }) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="300" height="286" viewBox="0 0 300 286" fill="none">
        <path d="M0 137.357V157.056L90.7625 196.06V171.83L31.2364 147.207L90.7625 122.78V98.5507L0 137.357Z" fill="url(#paint0_linear_689_12579)"/>
        <path d="M118.567 228.366L197.149 51.4705L178.29 43L99.7074 219.895L118.567 228.366Z" fill="url(#paint1_linear_689_12579)"/>
        <path d="M300 157.056V137.357L209.237 98.5507V122.78L268.764 147.207L209.237 171.83V196.06L300 157.056Z" fill="url(#paint2_linear_689_12579)"/>
        <defs>
          <linearGradient id="paint0_linear_689_12579" x1="169.99" y1="136" x2="169.99" y2="43" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint1_linear_689_12579" x1="169.99" y1="136" x2="169.99" y2="43" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint2_linear_689_12579" x1="169.99" y1="136" x2="169.99" y2="43" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

function CircleIcon({ className }) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="300" height="256" viewBox="0 0 300 256" fill="none">
        <g clip-path="url(#clip0_689_12581)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M164.423 240.172C143.248 240.394 122.381 235.094 103.877 224.796C85.3741 214.497 69.8737 199.554 58.9038 181.441L45.3711 189.637C57.7651 210.102 75.2777 226.984 96.183 238.62C117.088 250.255 140.665 256.243 164.589 255.992C188.513 255.742 211.959 249.262 232.616 237.191L228.625 230.361L232.616 237.191C253.273 225.12 270.428 207.875 282.391 187.155L268.689 179.244C258.101 197.583 242.917 212.847 224.634 223.531C206.35 234.215 185.598 239.951 164.423 240.172Z" fill="url(#paint0_linear_689_12581)"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M58.0892 178.871C47.5395 160.598 41.9331 139.892 41.8227 118.793C41.7122 97.6938 47.1014 76.93 57.4592 58.548C67.8169 40.1659 82.7857 24.8 100.89 13.9646C118.995 3.12917 139.611 -2.8017 160.705 -3.24359L160.374 -19.0611C136.531 -18.5617 113.229 -11.858 92.7657 0.389147C72.3021 12.6363 55.383 30.0043 43.6757 50.7814C31.9684 71.5585 25.877 95.0276 26.0019 118.876C26.1268 142.724 32.4636 166.128 44.3878 186.781L58.0892 178.871Z" fill="url(#paint1_linear_689_12581)"/>
          <path d="M200.617 -10.7959L160.537 12.4082L160.537 -34L200.617 -10.7959Z" fill="white"/>
          <path d="M296.598 191.713L256.518 214.917L256.518 168.509L296.598 191.713Z" fill="white"/>
          <path d="M74.0469 173.782L33.967 196.986L33.967 150.578L74.0469 173.782Z" fill="white"/>
          <path d="M68.7734 114.632V126.625L124.297 150.372V135.62L87.8822 120.628L124.297 105.757V91.005L68.7734 114.632Z" fill="white"/>
          <path d="M141.306 178.001L189.379 70.3016L177.841 65.1445L129.769 172.844L141.306 178.001Z" fill="white"/>
          <path d="M252.297 126.625V114.632L196.773 91.005V105.757L233.188 120.628L196.773 135.62V150.372L252.297 126.625Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M284.655 116.255C284.986 137.352 279.814 158.171 269.65 176.66L283.514 184.282C295.003 163.384 300.848 139.852 300.474 116.007C300.099 92.161 293.517 68.8247 281.378 48.2973C269.238 27.7699 251.959 10.7601 231.243 -1.05585C210.528 -12.8718 187.091 -19.0859 163.242 -19.0859V-3.26495C184.342 -3.26495 205.077 2.2329 223.404 12.6868C241.732 23.1406 257.019 38.1897 267.76 56.3508C278.5 74.5119 284.323 95.1583 284.655 116.255Z" fill="url(#paint2_linear_689_12581)"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear_689_12581" x1="51.8985" y1="206.479" x2="275.502" y2="206.479" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint1_linear_689_12581" x1="160.518" y1="-10.7957" x2="160.518" y2="183.275" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint2_linear_689_12581" x1="292.558" y1="191.186" x2="292.558" y2="-10.7958" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          <clipPath id="clip0_689_12581">
            <rect width="300" height="300" fill="white" transform="translate(0 -44)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

function SystemIcon({ className }) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="239" height="212" viewBox="0 0 239 212" fill="none">
        <rect x="0.898926" y="99.2083" width="238.1" height="13.0317" rx="2" fill="white"/>
        <path d="M25.2922 181.416L36.2389 152.955L47.1855 181.416H25.2922ZM29.8794 137.213L1.52246 210.19H14.1371L20.8093 192.884H51.6684L58.3406 210.19H70.9552L42.5983 137.213H29.8794Z" fill="white"/>
        <path d="M96.7998 159.523C92.9424 159.523 89.2935 160.044 85.8532 160.878C82.4128 161.816 79.4937 162.859 76.9916 164.006L81.0575 174.327C83.9766 173.076 86.6872 172.138 89.2935 171.512C91.7956 170.991 93.9849 170.678 95.653 170.678C96.7998 170.678 98.0508 170.887 99.3019 171.095C100.553 171.408 101.804 171.929 102.846 172.555C103.889 173.18 104.723 174.118 105.453 175.161C106.078 176.308 106.495 177.663 106.495 179.227V182.042C104.619 180.895 102.534 180.061 100.136 179.435C97.6338 178.81 95.3402 178.497 93.0467 178.497C90.2318 178.497 87.6255 178.914 85.2276 179.748C82.8298 180.582 80.7448 181.625 78.9724 183.084C77.2001 184.544 75.8448 186.316 74.8023 188.401C73.7598 190.486 73.3428 192.675 73.3428 195.178C73.3428 197.68 73.7598 199.973 74.8023 201.954C75.7406 204.039 77.0959 205.707 78.7639 207.167C80.432 208.626 82.3085 209.669 84.4979 210.399C86.6872 211.128 88.9808 211.441 91.3786 211.441C94.819 211.441 97.9466 210.816 100.761 209.356C103.576 207.896 105.766 206.124 107.434 203.935V210.19H119.006V177.976C119.006 172.346 117.025 167.863 113.063 164.527C109.102 161.191 103.681 159.523 96.7998 159.523ZM94.819 201.016C93.8807 201.016 92.9424 201.016 91.8999 200.807C90.8573 200.599 89.8148 200.286 88.9808 199.869C88.1467 199.452 87.417 198.722 86.8957 197.888C86.2702 197.054 86.0617 196.012 86.0617 194.656C86.0617 193.405 86.2702 192.258 86.8957 191.32C87.5212 190.486 88.251 189.756 89.1893 189.235C90.1276 188.714 91.1701 188.297 92.3169 188.088C93.3594 187.88 94.5062 187.671 95.653 187.671C97.2168 187.671 98.9891 187.88 101.074 188.297C103.055 188.714 104.827 189.339 106.495 190.173V195.282C105.349 196.741 103.889 197.992 102.012 199.243C100.136 200.494 97.7381 201.016 94.819 201.016Z" fill="white"/>
        <rect x="148.605" y="178.214" width="31.9768" height="31.9768" rx="2" fill="white"/>
        <rect x="202.669" y="133.209" width="31.9768" height="31.9768" rx="9" fill="white"/>
        <rect x="148.605" y="133.209" width="31.9768" height="31.9768" rx="15.9884" fill="url(#paint0_linear_2114_815)"/>
        <path d="M216.971 181.006C217.75 179.73 219.604 179.73 220.384 181.006L236.359 207.148C237.173 208.48 236.214 210.19 234.652 210.19H202.702C201.14 210.19 200.181 208.48 200.995 207.148L216.971 181.006Z" fill="url(#paint1_linear_2114_815)"/>
        <rect x="126.868" y="0.00170898" width="17.3756" height="17.3756" rx="8.68779" fill="white"/>
        <rect x="174.647" y="30.4089" width="17.3756" height="17.3756" rx="8.68779" fill="white"/>
        <rect x="218.097" y="60.8179" width="17.3756" height="17.3756" rx="8.68779" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M235.027 8.99588C235.027 10.1004 234.131 10.9959 233.027 10.9959H133.057C131.952 10.9959 131.057 10.1004 131.057 8.99588V8.23389C131.057 7.12932 131.952 6.23389 133.057 6.23389H233.027C234.131 6.23389 235.027 7.12932 235.027 8.23389V8.99588Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M237.408 39.1714C237.408 40.2672 236.519 41.1556 235.424 41.1556H133.041C131.945 41.1556 131.057 40.2672 131.057 39.1714C131.057 38.0756 131.945 37.1873 133.041 37.1873H235.424C236.519 37.1873 237.408 38.0756 237.408 39.1714Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M233.293 69.6723C233.293 70.7768 232.398 71.6723 231.293 71.6723H133.212C132.107 71.6723 131.212 70.7768 131.212 69.6723V69.3284C131.212 68.2238 132.107 67.3284 133.212 67.3284H231.293C232.398 67.3284 233.293 68.2238 233.293 69.3284V69.6723Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.898926 8.00001C0.898926 3.58173 4.48065 0 8.89893 0H101.496C105.915 0 109.496 3.58172 109.496 8V70.19C109.496 74.6083 105.915 78.1901 101.496 78.1901H8.89892C4.48064 78.1901 0.898926 74.6083 0.898926 70.1901V8.00001ZM42.8754 43.2561C45.8441 41.2769 45.8441 36.9147 42.8754 34.9356L14.5399 16.0452C13.5418 15.3798 13.2721 14.0313 13.9375 13.0333C14.6029 12.0352 15.9514 11.7655 16.9495 12.4309L50.2578 34.6365C51.9373 35.7561 54.1253 35.7561 55.8048 34.6365L89.1156 12.4292C90.1137 11.7639 91.4622 12.0336 92.1276 13.0316C92.793 14.0297 92.5233 15.3782 91.5252 16.0436L63.1872 34.9356C60.2186 36.9147 60.2186 41.2769 63.1872 43.2561L91.5252 62.148C92.5233 62.8134 92.793 64.1619 92.1276 65.16C91.4622 66.158 90.1137 66.4277 89.1156 65.7624L55.8048 43.5552C54.1253 42.4355 51.9373 42.4355 50.2578 43.5552L16.9495 65.7607C15.9514 66.4261 14.6029 66.1564 13.9375 65.1583C13.2721 64.1603 13.5418 62.8118 14.5399 62.1464L42.8754 43.2561Z" fill="url(#paint2_linear_2114_815)"/>
        <defs>
          <linearGradient id="paint0_linear_2114_815" x1="162.017" y1="150.027" x2="180.582" y2="150.025" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint1_linear_2114_815" x1="215.528" y1="195.032" x2="238.219" y2="195.029" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint2_linear_2114_815" x1="53.4929" y1="81.6212" x2="53.4392" y2="2.7056" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}





export default {
  MeteorIcon,
  CodeIcon,
  CircleIcon,
  SystemIcon,
}

import React from "react"
import { withStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import ServiceBgIcon from "../svg/services-bg-icons"
import { LinkWithArrow } from "./link-with-arrow"
import { Link } from "gatsby"

function ServiceCard(props) {
  const {
    classes,
    bgName,
    title,
    list = [],
    text = "",
    link,
    linkText
  } = props

  function renderList() {
    if (!list) return null

    return (
      <ul className={classes.list}>
        {list.map((text)=> <li>{text}</li>)}
      </ul>
    )
  }

  function renderText() {
    if (!text) return null

    return (
      <Typography
        variant="h5"
        className={classes.text}
        children={text}
      />
    )
  }

  function renderBg() {
    const Icon = ServiceBgIcon[bgName];
    const bgClass = bgName === 'SystemIcon' ? `${classes.bg} ${classes.systemIconBg}` : classes.bg

    return (
      <div className={bgClass}>
        <Icon/>
      </div>
    )
  }

  return (
    <Link to={link} target="_blank">
      <div className={classes.card}>
        {renderBg()}
        <div className={classes.content}>
          <Typography
            variant="h3"
            className={classes.title}
            children={title}
          />

          {renderList()}

          {renderText()}

          <LinkWithArrow
            text={linkText}
            link={link}
          />
        </div>
      </div>
    </Link>
  )
}

const styles = theme => {
  const bgStyles = {
    opacity: 0,
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#ECF2F6",
    transition: "opacity .3s ease-out",
  }
  return (
    {
      card: {
        padding: "30px 30px 20px",
        border: "1px solid #DCE5EB",
        borderRadius: 10,
        position: "relative",
        overflow: "hidden",
        display: "block",

        [theme.breakpoints.down("sm")]: {
          padding: "30px 20px"
        },

        "&:hover $bg": {
          opacity: 1
        }
      },
      content: {
        position: "relative"
      },
      title: {
        color: theme.palette.text.secondary,
        fontWeight: 700,
        marginBottom: 20,

        [theme.breakpoints.down("sm")]: {
          fontSize: 28,
          lineHeight: "36px"
        },
      },
      list: {
        display: "block",
        color: theme.palette.text.primary,
        marginBottom: 33,
        overflow: "visible",

        "& > li": {
          textIndent: 20,
          padding: 0,
          float: "none",
          fontSize: 20,
          lineHeight: "30px",
          fontWeight: 300,
          color: theme.palette.text.primary,
          position: "relative",

          "&:after": {
            content: '""',
            display: "block",
            position: "absolute",
            backgroundColor: theme.palette.text.primary,
            width:4,
            height: 4,
            borderRadius: "50%",
            left: 1,
            top: 13
          }
        }
      },
      text: {
        color: theme.palette.text.primary,
        fontWeight: 300,
        marginBottom: 33,
      },
      bg: {
        ...bgStyles,

        "& > div": {
          position: "absolute",
          right: "7%",
          top: "-2%"
        }
      },
      systemIconBg: {
        ...bgStyles,

        "& > div": {
          position: "absolute",
          right: "4%",
          top: "7%"
        }
      }
    }
  )
}

// eslint-disable-next-line
ServiceCard = withStyles(styles)(ServiceCard)

export { ServiceCard }

import React from "react"
import { withStyles } from "@material-ui/core"
import { ContentContainer } from "../moleculs/content-container"
import { PostPreviewItem } from "../moleculs/post-preview-item"
import { ServicesContentGrid } from "../moleculs/services-content-grid"


function OurBlogBlock(props) {
  const { classes, title, blogPosts = [] } = props

  return (
    <ContentContainer>
      <ServicesContentGrid
        title={title}
        data={blogPosts}
        isCardContainer={true}
        ItemComponent={PostPreviewItem}
      />
    </ContentContainer>
  )
}

const styles = theme => ({})

// eslint-disable-next-line
OurBlogBlock = withStyles(styles)(OurBlogBlock)

export { OurBlogBlock }

import React, { useState } from "react"
import { withStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import axios from 'axios';

function SubscribeEmailForm(props) {
  const {
    classes,
  } = props

  const [email, setEmail] = useState('')
  const [sentEmail, setSentEmail] = useState(false)

  function validateEmail (email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  function updateEmail (event) {
    setEmail(event.target.value)
  }

  function handleClick (e) {
    e.preventDefault()

    if (validateEmail(email)) {
      try {
        axios.post(
          'http://app.astraloadhq.com:4000/send_email',
          { message: email }
        )
          .then((res)=> {
            setSentEmail(true)
          })
          .catch((err)=>{
            console.log('err', err)
          })
      } catch (err) {
        console.log('err', err)
      }
    }
  }

  function renderSentEmailMessage () {
    return (
      <div>
        <Typography
          variant="body1"
          className={classes.message}
          children={'You are subscribed'}
        />
      </div>
    )
  }

  if (sentEmail) return renderSentEmailMessage()

  return (
    <form className={classes.container} onSubmit={handleClick}>
      <div className={classes.inputContainer}>
        <input
          type="text"
          onChange={updateEmail}
          placeholder={"Email"}
        />
      </div>
      <Button
        className={classes.buttonStyle}
        disabled={!email || !validateEmail(email)}
        type="submit"
      >
        Subscribe
      </Button>
    </form>
  )
}

const styles = theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  inputContainer: {
    marginRight: 5,
    width: "100%",

    "& input": {
      height: 50,
      backgroundColor: "#ECF2F6",
      borderRadius: 10,
      border: "1px solid #ECF2F6",
      boxShadow: "none",
      outline: "none",
      padding: "13px 11px",
      boxSizing: "border-box",
      width: "100%",

      "&::placeholder": {
        color: "#D0DAE1"
      },

      "&:hover": {
        borderColor: "#D0DAE1",
      },

      "&:focus": {
        borderColor: "#FFB533",
        backgroundColor: "#FFFFFF"
      }
    }
  },
  buttonStyle: {
    width: 121,
    transition: "none",
    boxShadow: "none",

    "&:disabled": {
      backgroundColor: "#D0DAE1",
      backgroundImage: "none",
      color: "#fff"
    }
  },

  message: {
    color: "#D0DAE1",
    fontWeight: "700",
    fontSize: 18,
    lineHeight: "50px"
  }
})

// eslint-disable-next-line
SubscribeEmailForm = withStyles(styles)(SubscribeEmailForm)

export { SubscribeEmailForm }

import React from "react"
import Seo from "../components/seo"
import { Constants } from "../constants"
import { Footer } from "../components/organism/footer"
import { Navigation } from "../components/organism/navigation"
import { IntroduceMainBlock } from "../components/moleculs/introduce-main-block"
import { ServicesBlock } from "../components/organism/services-block"
import { OurProjectsBlock } from "../components/organism/our-projects-block"
import { OurBlogBlock } from "../components/organism/our-blog-block"
import { ServicesFeedbackBlock } from "../components/organism/services-feedback-block"
import { MeteorExpertiseBlock } from "../components/organism/meteor-expertise-block"
import { ContactsBlock } from "../components/organism/contacts-block"
import { OurJourneyBlock } from "../components/organism/our-journey-block"

export default function IndexPage({ location }) {
  const {
    ourProjects,
    ourProjectsBlock,
    ourServices,
    services,
    servicesFeedbacks,
    ourBlogBlock,
    blogPosts,
    meteorExpertiseBlock,
    servicesContactCallToActionBlock,
    ourJourneyBlock,
    ourJourney
  } = Constants

  return (
    <div>
      <Seo
        location={location}
        title="Astraload services"
        description="Astraload – elegant solutions by talented professionals"
      />

      <Navigation
        isHeader
        isDarkLogo={true}
        linkColor="#1A1A1A"
      />

      <main>
        <IntroduceMainBlock />

        <MeteorExpertiseBlock
          {...meteorExpertiseBlock}
        />

        <ServicesBlock
          {...ourServices}
          services={services}
        />

        <ServicesFeedbackBlock
          {...servicesFeedbacks}
          isGrayBg={true}
        />

        <OurProjectsBlock
          {...ourProjectsBlock}
          ourProjects={ourProjects}
        />

        <OurBlogBlock
          {...ourBlogBlock}
          blogPosts={blogPosts}
        />

        <OurJourneyBlock
          {...ourJourneyBlock}
          ourJourney={ourJourney}
        />

        <ContactsBlock
          callToActionBlockInfo={servicesContactCallToActionBlock}
        />
      </main>

      <Footer />
    </div>
  )
}

import React from "react"

function Rapid({ className }) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="416" height="416" viewBox="0 0 416 416" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M161.46 131.45C211.39 67.3046 289.373 26.0037 377 26C380.448 25.9999 383.754 27.3694 386.193 29.8074C388.631 32.2454 390 35.5521 390 39C390 126.631 348.7 204.62 284.552 254.555C285.506 260.57 286 266.731 286 273C286 337.617 233.617 390 169 390C161.82 390 156 384.18 156 377V305.387C155.84 305.271 155.682 305.151 155.526 305.028C139.024 291.943 124.072 276.991 110.987 260.49C110.86 260.329 110.737 260.166 110.618 260H39C31.8203 260 26 254.18 26 247C26 182.383 78.3827 130 143 130C149.274 130 155.441 130.495 161.46 131.45ZM260 117C238.461 117 221 134.461 221 156C221 177.539 238.461 195 260 195C281.539 195 299 177.539 299 156C299 134.461 281.539 117 260 117Z" fill="#D0DAE1"/>
        <path d="M91.1796 298.858C96.9345 294.565 98.1195 286.42 93.8264 280.665C89.5334 274.91 81.388 273.725 75.6332 278.018C53.4229 294.587 39 321.11 39 350.999C39 355.775 39.3691 360.474 40.0826 365.066C40.9515 370.659 45.3396 375.047 50.9327 375.916C55.525 376.63 60.2238 376.999 65 376.999C94.8885 376.999 121.412 362.576 137.98 340.365C142.273 334.611 141.088 326.465 135.333 322.172C129.579 317.879 121.433 319.064 117.14 324.819C105.268 340.734 86.3341 350.999 65 350.999C65 329.664 75.2645 310.731 91.1796 298.858Z" fill="#D0DAE1"/>
      </svg>
    </div>
  )
}

export { Rapid }
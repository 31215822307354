import React, { useRef } from "react"
import { TitleWithDescriptionBlock } from "../moleculs/title-with-description-block"
import { withStyles } from "@material-ui/core"
import useShouldAnimate from "../../hooks/useShouldAnimate"
import useScrollPosition from "../../hooks/useScrollPosition"
import { ServiceCard } from "../moleculs/service-card"
import { ContentContainer } from "../moleculs/content-container"
import { Element } from "react-scroll"

function ServicesBlock(props) {
  const { classes, title, description, services } = props
  const scrollPosition = useScrollPosition()
  const sectionRef = useRef()
  const sectionPosition = sectionRef?.current?.offsetTop
  const shouldAnimate = useShouldAnimate(sectionPosition, scrollPosition)

  function renderServiceCard (service, key) {
    return (
      <div className={classes.serviceCard} key={key}>
        <ServiceCard
          {...service}
        />
      </div>
    )
  }

  function renderServices () {
    return (
      <div className={classes.serviceCardWrapper}>
        {services.map(renderServiceCard)}
      </div>
    )
  }

  return (
    <ContentContainer>
      <div ref={sectionRef}>
        <Element name="ourServicesBlock">
          <TitleWithDescriptionBlock
            title={title}
            description={description}
            left={true}
            shouldAnimate={shouldAnimate}
          />
        </Element>
        {renderServices()}
      </div>
    </ContentContainer>
  )
}

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.text.white,
    ...theme.defaultHorizonContainer,

    "& > div": {
      padding: "70px 0",
    },
    [theme.breakpoints.down("sm")]: {

    },
    [theme.breakpoints.down("xs")]: {

      "& > div": {
        paddingTop: "91px",
      },
    },
  },
  serviceCardWrapper: {
    margin: "100px auto 0",
    columnCount: 2,
    gap: 30,

    [theme.breakpoints.down("sm")]: {
      columnCount: 1,
      marginTop: 50
    },
  },
  serviceCard: {
    display: "inline-block",
    width: "100%",
    marginBottom: 30
  }
})

// eslint-disable-next-line
ServicesBlock = withStyles(styles)(ServicesBlock)

export { ServicesBlock }

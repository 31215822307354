import React from "react"

function Joyful({ className }) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="416" height="416" viewBox="0 0 416 416" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M208 39C114.664 39 39 114.664 39 208C39 301.336 114.664 377 208 377C301.336 377 377 301.336 377 208C377 114.664 301.336 39 208 39ZM162.5 143C153.128 143 148.144 150.259 146.276 153.994C144.02 158.506 143 163.83 143 169C143 174.17 144.02 179.494 146.276 184.006C148.144 187.741 153.128 195 162.5 195C171.872 195 176.857 187.741 178.724 184.006C180.98 179.494 182 174.17 182 169C182 163.83 180.98 158.506 178.724 153.994C176.857 150.259 171.872 143 162.5 143ZM237.276 153.994C239.144 150.259 244.128 143 253.5 143C262.872 143 267.856 150.259 269.724 153.994C271.98 158.506 273 163.83 273 169C273 174.17 271.98 179.494 269.724 184.006C267.856 187.741 262.872 195 253.5 195C244.128 195 239.144 187.741 237.276 184.006C235.02 179.494 234 174.17 234 169C234 163.83 235.02 158.506 237.276 153.994ZM272.347 272.347C277.424 267.27 277.424 259.039 272.347 253.962C267.27 248.885 259.039 248.885 253.962 253.962C228.578 279.346 187.422 279.346 162.038 253.962C156.961 248.885 148.73 248.885 143.653 253.962C138.576 259.039 138.576 267.27 143.653 272.347C179.191 307.885 236.809 307.885 272.347 272.347Z" fill="#D0DAE1"/>
      </svg>
    </div>
  )
}

export { Joyful }
import React from "react"
import { withStyles } from "@material-ui/core"
import { ContentContainer } from "../moleculs/content-container"
import { JourneySocialMediaItem } from "../moleculs/journey-social-media-item"
import { ServicesContentGrid } from "../moleculs/services-content-grid"


function OurJourneyBlock(props) {
  const { title, ourJourney } = props

  return (
    <ContentContainer>
      <ServicesContentGrid
        title={title}
        data={ourJourney}
        ItemComponent={JourneySocialMediaItem}
      />
    </ContentContainer>
  )
}

const styles = theme => ({})

// eslint-disable-next-line
OurJourneyBlock = withStyles(styles)(OurJourneyBlock)

export { OurJourneyBlock }

import React from "react"

function ClientRabbit({ className }) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="78" height="51" viewBox="0 0 78 51" fill="none">
        <g clip-path="url(#clip0_1252_15301)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0872 27.0314C12.0872 27.0314 15.8431 26.0506 9.19607 30.9894C2.54908 35.9272 -0.847037 44.0935 0.683448 44.8923C1.59615 45.3693 9.19607 39.5535 20.9998 35.4614C25.7063 33.8303 31.7579 35.6379 38.9757 35.4614C42.64 35.372 48.0268 33.5142 49.7841 33.5142C50.3047 33.5142 50.9158 35.1452 54.7733 37.0645C58.2453 38.7916 63.9495 37.5057 67.9924 39.2373C72.0365 40.9689 75.2672 44.8923 75.5443 44.8923C76.4581 44.8923 77.5831 43.6132 76.8268 41.0884C76.0716 38.5637 74.7489 37.7124 72.1828 34.6503C69.6167 31.5871 63.9863 27.0314 63.9863 27.0314C63.9863 27.0314 64.4008 24.51 62.7374 22.2031C61.0751 19.8951 56.497 22.2031 56.497 22.2031C56.497 22.2031 48.1955 19.6449 39.932 19.2092C31.6685 18.7735 25.4538 19.7599 24.5713 19.7599C23.6865 19.7599 23.8306 18.8908 23.8306 17.8988C23.8306 17.3525 30.7938 16.022 35.44 13.6838C39.2182 11.7846 42.4478 8.3472 42.1238 7.93497C41.4022 7.01556 34.0022 8.39635 32.179 8.71697C30.0196 9.0968 26.4749 10.0743 26.4749 10.0743C26.4749 10.0743 33.6269 6.72175 32.179 6.10509C31.2373 5.70292 27.4513 6.00008 23.8306 7.93497C20.2089 9.86986 19.4313 11.0853 16.9602 12.7163C14.8376 14.1172 12.3453 14.5518 10.5903 15.7997C6.49371 18.712 4.21138 21.9607 4.21138 22.927C4.21138 27.9709 12.0872 27.0314 12.0872 27.0314Z" fill="url(#paint0_linear_1252_15301)"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear_1252_15301" x1="0.320312" y1="5.94922" x2="0.320312" y2="44.9199" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFB533"/>
            <stop offset="1" stop-color="#FFA600"/>
          </linearGradient>
          <clipPath id="clip0_1252_15301">
            <rect width="77.0829" height="51" fill="white" transform="translate(0.320312)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export { ClientRabbit }
import React from "react"

function MeteorLogo({ className }) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="205" height="50" viewBox="0 0 205 50" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M121.523 0.5L164.27 45.8331C164.27 45.8331 165.727 46.8615 166.84 45.6616C167.954 44.462 167.098 43.2625 167.098 43.2625L121.523 0.5ZM135.058 4.78471L167.611 39.92C167.611 39.92 169.068 40.9484 170.181 39.7485C171.295 38.549 170.438 37.3494 170.438 37.3494L135.058 4.78471ZM125.55 13.9541L158.102 49.0898C158.102 49.0898 159.559 50.1182 160.672 48.9183C161.786 47.7184 160.929 46.5188 160.929 46.5188L125.55 13.9541ZM146.501 8.46985L169.243 33.0169C169.243 33.0169 170.261 33.7349 171.039 32.8972C171.817 32.0587 171.218 31.2206 171.218 31.2206L146.501 8.46985ZM128.682 24.5803L151.425 49.1277C151.425 49.1277 152.443 49.8457 153.22 49.008C153.998 48.1695 153.4 47.3314 153.4 47.3314L128.682 24.5807V24.5803ZM158.188 13.783L168.496 24.9421C168.496 24.9421 168.999 25.2788 169.384 24.8863C169.769 24.4933 169.473 24.1004 169.473 24.1004L158.188 13.783H158.188ZM134.373 35.8926L144.681 47.0518C144.681 47.0518 145.184 47.3885 145.569 46.9959C145.954 46.603 145.658 46.2101 145.658 46.2101L134.373 35.8926Z" fill="#DF4F4F"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M178.271 18.8477V48.9388H185.071V39.2324H190.171L196.728 48.9388H204.5L197.214 38.5044C197.214 38.5044 203.684 35.9442 203.684 29C203.684 19.1963 194.786 18.8485 194.786 18.8485L178.271 18.8477ZM185.071 24.6726V33.4087H193.814C193.814 33.4087 197.156 33.4079 197.156 29.0398C197.156 24.5062 193.086 24.6726 193.086 24.6726H185.071ZM102.986 18.8485V48.9388H125.814V43.1146H109.786V36.8063H123.629V30.9821H109.786V24.6678H125.814V18.8485H102.986H102.986ZM69.9572 18.8485V24.6726H78.9428V48.9388H85.7429V24.6726H94.7285V18.8485H69.9572ZM38.8715 18.8485V48.9388H61.7V43.1146H45.6713V36.8063H59.5142V30.9821H45.6713V24.6678H61.7V18.8485H38.8715ZM0.5 18.8477V48.9396L7.29535 48.9392V30.0104L15.5526 42.1437L23.8142 30.0104V48.9396H30.6143V18.8477H23.8142L15.553 31.2235L7.29535 18.8477H0.5Z" fill="#26262C"/>
      </svg>
    </div>
  )
}

export { MeteorLogo }